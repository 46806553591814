import React, { useEffect, useState } from 'react'

export const AdvancedEditor = () => {
	// const query = 'open'
	async function fletcher() {
		setIsLoading(true)
		setIsErrored(false)
		return await fetch(`https://api.pexels.com/v1/search?query=${queryString}&per_page=5`, {
			method: 'GET',
			headers: {
				Authorization: process.env.REACT_APP_API_KEY,
			},
		})
			.then((resp) => resp.json())
			.then((json) => {
				if (json.photos.length > 0) {
					setOrbiter(json)
					setIsLoading(false)
				} else {
					setIsErrored(true)
				}
			})
	}

	// client.photos.search({ query, per_page: 1 }).then((photos) => {})

	const [orbiter, setOrbiter] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isErrored, setIsErrored] = useState(false)
	const [queryString, setQueryString] = useState('unknown')

	useEffect(() => {
		fletcher()
	}, [queryString])

	return (
		<div className='flex flex-col m-2 space-y-2 align-middle border place-items-center'>
			<input
				type='text'
				onChange={(e) => {
					setQueryString(e.target.value)
				}}
			/>
			<img className='w-24 h-12 ' src={'https://picsum.photos/600'} alt='query' />
			<h1>
				Search Phrase: <span className='italic font-bold text-brand-700'>{queryString}</span>
			</h1>
			{isLoading ? (
				<div className='mx-auto text-center h-96 w-96'> {isErrored ? 'Oooh looks like an error there!' : 'LOADING...'} </div>
			) : (
				<div className='w-full mx-auto bg-cover h-96' style={{ backgroundImage: `url(${orbiter?.photos[0].src.original ?? ''})` } ?? 'Loading...'}></div>
			)}
			{isLoading ? 'LOADING...' : <div className='mx-12 text-xs italic text-center'>{`URL: ${JSON.stringify(orbiter?.photos[0].url) ?? 'Loading...'}`}</div>}
		</div>
	)
}
