import React from 'react'

export const SlideImageSet = (props) => {
	return (
		<div className='container p-2 mx-auto'>
			{props.array.map((url) => {
				return <SlideImage url={url} key={url}></SlideImage>
			})}
		</div>
	)
}

export const SlideImage = (props) => {
	return (
		<div className='p-2 mx-auto my-8 border rounded-md h-60 w-96 overflow-clip'>
			<div className='border rounded-md h-3/4 overflow-clip' id={props.url.substring(props.url.length - 3)}>
				<img className='object-cover w-full h-full' src={props.url} alt='Random' />
			</div>
			<p className='m-4 italic text-center'>{props.url.substring(props.url.length - 3) + '   ' + props.url}</p>
		</div>
	)
}
