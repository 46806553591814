import React from 'react'
import { SlideShowEditor } from '../components/SlideShowEditor'

export const SlideShow = () => {
	return (
		<div className='flex flex-col w-screen h-screen bg-brand-300'>
			{/* <div className='w-full h-16'>
				<h1 className='p-2 text-2xl font-bold text-left'>SlideShow</h1>
			</div> */}

			<SlideShowEditor></SlideShowEditor>
		</div>
	)
}

export default SlideShow
