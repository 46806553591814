import React from 'react'
import { DataDisplay } from '../components/DataDisplay'
import { UseCase } from '../components/UseCase'

export const Dashboard = () => {
	return (
		<div className='container mx-auto my-4 '>
			<h1 className='text-2xl font-bold text-center'>DASHBOARD</h1>
			<DataDisplay></DataDisplay>
			<UseCase></UseCase>
		</div>
	)
}

export default Dashboard
