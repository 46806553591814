import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import { ContextProvider } from './context/State'
import Landing from './pages/Landing'
import NotFound from './pages/NotFound'
import Dashboard from './pages/Dashboard'
import SlideShow from './pages/SlideShow'

export const App = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<Landing />
				</Route>

				{/* <ContextProvider></ContextProvider> */}
				<Route path='/in/:id'>
					<Dashboard></Dashboard>
				</Route>

				<Route path='/dash'>
					<Dashboard></Dashboard>
				</Route>

				<Route path='/slide'>
					<SlideShow></SlideShow>
				</Route>

				<Route component={NotFound} />
			</Switch>
		</Router>
	)
}

export default App
