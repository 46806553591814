import React, { useState } from 'react'
import { AdvancedEditor } from './AdvancedEditor'
import { Editor } from './Editor'
import { RichTextEditor } from './RichTextEditor'

import { SlideImageSet } from './SlideImage'

export const SlideShowEditor = () => {
	return (
		<div className='flex flex-row h-full bg-brand-100'>
			<div className='w-1/2 overflow-y-scroll '>
				{/* <Editor></Editor> */}
				<AdvancedEditor></AdvancedEditor>
			</div>
			<div className='w-1/2 overflow-y-scroll border-r-2'>
				<SlideImageSet array={['https://picsum.photos/530', 'https://picsum.photos/270', 'https://picsum.photos/410', 'https://picsum.photos/500']}></SlideImageSet>
			</div>
		</div>
	)
}
